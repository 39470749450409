table {
  border-collapse: separate;
  width: 100%;
  background-color: '#fff';
  border: 1px solid rgba(212, 212, 212, 0.3);
  border-radius: 4px;
  border-spacing: 0;
}

table td,
table th {
  padding: 12px;
}

table tbody tr:hover {
  box-shadow: inset 0 0 100px 100px rgba(212, 212, 212, 0.3);
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: rgba(74, 85, 104, 0.5);
  color: rgba(74, 85, 104, 1);
}
